
import { reactive } from 'vue'
import { Options, Vue } from 'vue-class-component'
import { NodeData, TreeRowCustomStyles, TreeRowCustomOptions } from './interface'
import { copyOptions, recCallNodes } from '@/components/helper'

@Options({
  props: {
    customOptions: Object,
    customStyles: Object,
    node: Object,
    parentNode: Object,
    depth: Number
  }
})

export default class Tree extends Vue {
  customOptions!: TreeRowCustomOptions
  customStyles!: TreeRowCustomStyles
  node!: NodeData
  parentNode!: NodeData
  depth!: number

  styles: TreeRowCustomStyles = reactive({
    row: {
      style: {
        width: '500px',
        cursor: 'pointer'
      },
      child: {
        class: '',
        style: {
          height: '35px'
        },
        active: {
          class: '',
          style: {
            height: '35px'
          }
        }
      }
    },
    rowIndent: {
      paddingLeft: '20px'
    },
    expanded: {
      class: 'expanded_icon'
    },
    addNode: {
      class: 'add_icon',
      style: {
        color: '#007AD5'
      }
    },
    editNode: {
      class: 'edit_icon',
      style: {
        color: '#007AD5'
      }
    },
    deleteNode: {
      class: 'delete_icon',
      style: {
        color: '#EE5F5B'
      }
    },
    selectIcon: {
      class: 'folder_icon',
      style: {
        color: '#007AD5'
      },
      active: {
        class: 'folder_icon_active',
        style: {
          color: '#2ECC71'
        }
      }
    },
    text: {
      style: {},
      class: 'capitalize',
      active: {
        style: {
          'font-weight': 'bold',
          color: '#2ECC71'
        }
      }
    }
  })

  options: TreeRowCustomOptions = reactive({
    events: {
      expanded: {
        state: true,
        fn: null
      },
      selected: {
        state: false,
        fn: null
      },
      checked: {
        state: false,
        fn: null
      },
      editableName: {
        state: false,
        fn: null,
        calledEvent: null
      }
    },
    addNode: { state: false, fn: null, appearOnHover: false },
    editNode: { state: false, fn: null, appearOnHover: false },
    deleteNode: { state: false, fn: null, appearOnHover: false },
    showTags: false
  })

  get selected (): boolean {
    return this.node.state.selected
  }

  get expanded (): boolean {
    return this.node.state.expanded
  }

  mounted () {
    copyOptions(this.customOptions, this.options)
    copyOptions(this.customStyles, this.styles)
    if (this.node.checkable !== undefined) this.options.events.checked.state = this.node.checkable
    if (this.node.selectable !== undefined) this.options.events.selected.state = this.node.selectable
    if (this.node.expandable !== undefined) this.options.events.expanded.state = this.node.expandable
  }

  // Handle TreeRow events
  toggleEvent (eventType: string, node: NodeData) {
    const event = (this.options.events as any)[eventType]
    if (eventType === 'editableName' && event.calledEvent) {
      this.toggleEvent(event.calledEvent, node)
    } else if (event.state === true) {
      const fnName = event.fn || this.defaultEventFn(eventType)
      if (fnName) { fnName(node, this) }
    }
  }

  // Default functions for TreeRow event, can be overwritten in CustomOptions
  defaultEventFn (eventType: string): any {
    if (eventType === 'expanded') {
      return this.toggleExpanded
    } else if (eventType === 'selected') {
      return this.toggleSelected
    } else if (eventType === 'checked') {
      return this.toggleChecked
    }

    return null
  }

  // Default tree behavior when node is expanded
  toggleExpanded (node: NodeData) {
    this.node.state.expanded = !this.node.state.expanded
    this.emitNodeExpanded(node, this.node.state.expanded)
  }

  // Default tree behavior when node is selected
  toggleSelected (node: NodeData) {
    this.node.state.selected = !this.node.state.selected
    this.emitNodeSelected(node)
  }

  // Default tree behavior when node is checked
  toggleChecked (node: NodeData) {
    const state = !this.node.state.checked
    this.node.state.checked = state
    recCallNodes(state, 'checked', this.node.nodes)
    this.$emit('emitNodeChecked', node)
    this.emitNodeChecked(node)
  }

  // Redirect the event toward the Tree component
  emitNodeSelected (nodeSelected: NodeData) {
    this.$emit('emitNodeSelected', nodeSelected)
  }

  // Redirect the event toward the Tree component
  emitNodeExpanded (node: NodeData, state: boolean) {
    this.$emit('emitNodeExpanded', node, state)
  }

  // Redirect the event toward the Tree component
  emitNodeChecked (nodeChecked: NodeData) {
    this.$emit('emitNodeChecked', nodeChecked)
  }
}
